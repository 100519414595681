/** @jsx jsx */
import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { css, styled, jsx } from '@compiled/react';
import debounce from 'lodash/debounce';

import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

import { DocumentScrollListener } from '@confluence/scroll';

/* translate3d forces Safari to use hardware acceleration. This will avoid flickering and ghosts */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AnimatedBox = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'@keyframes stickyDisappear': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'0%': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
			transform: (props) => `translate3d(0px, ${props.offsetHeight}px, 0px)`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'100%': {
			transform: 'translate3d(0, -100%, 0)',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'@keyframes stickyAppear': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'0%': {
			transform: 'translate3d(0px, -100px, 0px)',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'100%': {
			transform: 'translate3d(0px, 0px, 0px)',
		},
	},
	animationDuration: '0.25s',
	animationTimingFunction: 'ease',
	animationDelay: '0s',
	animationIterationCount: '1',
	animationDirection: 'normal',
	animationFillMode: 'forwards',
	animationPlayState: 'running',
	borderBottom: 'none',
	zIndex: '12',
	backgroundColor: token('elevation.surface'),
	marginLeft: '-10px',
	paddingLeft: '10px',
	paddingBottom: '10px',
});

const wrapperStyle = css({ zIndex: '10' });

export class StickyWrapper extends PureComponent {
	static propTypes = {
		createAnalyticsEvent: PropTypes.func.isRequired,
		children: PropTypes.node,
		stickyStateChangeHandler: PropTypes.func,
		closeOpenDialogAndMenu: PropTypes.func,
		offsetHeight: PropTypes.number,
	};

	static defaultProps = {
		stickyStateChangeHandler: () => {},
		closeOpenDialogAndMenu: () => {},
		offsetHeight: 0,
	};

	state = {
		stuckState: null, // top, hidden, null
		width: 0,
	};

	componentDidMount() {
		this._isMounted = true;
		if (this.getHeaderComponent() && window.addEventListener) {
			window.addEventListener('scroll', this.stick);
			window.addEventListener('resize', this.stick);
			this.stick();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		if (window.removeEventListener) {
			window.removeEventListener('scroll', this.stick);
			window.removeEventListener('resize', this.stick);
			window.clearTimeout(this.repaintTimeout);
			window.clearTimeout(this.notifyTableTimeout);
		}
	}

	containerRef = React.createRef();
	_isMounted = false;
	lastDownwardTop = 0;
	lastTop = 0;
	repaintTimeout = null;
	notifyTableTimeout = null;
	height = 0;

	preventNextScrollFromTriggeringStickyHeader = false;

	ignoreNextScroll = () => {
		this.preventNextScrollFromTriggeringStickyHeader = true;
		if (this.state.stuckState === 'top') {
			this.setState(
				{
					stuckState: 'hidden',
				},
				this.handleStickyStateChange,
			);
		}
	};

	stick = debounce(() => {
		if (!this._isMounted) {
			return;
		}

		this.setState(
			{
				stuckState: null,
			},
			this.handleStickyStateChange,
		);
		return;
	}, 50);

	handleStickyStateChange = () => {
		const { stickyStateChangeHandler } = this.props;
		const { stuckState } = this.state;

		if (stuckState !== 'top') {
			stickyStateChangeHandler({
				stuckState,
				headerHeight: 0,
			});

			return;
		}

		this.sendHeaderIsStickyAnalyticsEvent();
		// Schedule a timeout after so the table header can be adjusted after the animation
		window.clearTimeout(this.notifyTableTimeout);
		this.notifyTableTimeout = window.setTimeout(() => {
			// Need to check the state again
			const { stuckState } = this.state;

			const clonedHeaderDOM =
				this.containerRef &&
				this.containerRef.current.querySelector("[data-test-id='header-animated-box']");

			const boundaries = (clonedHeaderDOM && clonedHeaderDOM.getBoundingClientRect()) || {};
			const headerHeight = stuckState === 'top' ? boundaries.height || 0 : 0;
			stickyStateChangeHandler({
				stuckState,
				headerHeight,
			});
		}, 250); // After the animation - set in css above (animation-duration: 0.25s;)
	};

	getHeaderComponent() {
		const { children } = this.props;
		if (children && React.Children.only(children)) {
			return children;
		}
		return null;
	}

	cloneHeader() {
		const header = this.getHeaderComponent();
		if (!header) {
			return null;
		}
		const { stuckState } = this.state;
		if (!stuckState) {
			return header;
		}

		const cloneProps = {
			className: `${header.props.className || ''} sticky-header stuck-state-${stuckState}`,
			isSticky: true,
			style: { backgroundColor: token('elevation.surface') },
		};
		return cloneElement(header, cloneProps);
	}

	sendHeaderIsStickyAnalyticsEvent() {
		const { createAnalyticsEvent } = this.props;
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: 'viewPageScreen',
				action: 'stuck',
				actionSubject: 'contentHeader',
				attributes: {
					source: 'v2',
				},
			},
		}).fire();
	}

	setWidth = debounce((width) => {
		this._isMounted && this.setState({ width });
	}, 100);

	render() {
		const { stuckState, width } = this.state;
		const { offsetHeight } = this.props;
		const hiddenStateAnimation = stuckState === 'hidden' ? 'stickyDisappear' : 'none';
		return (
			<div
				css={wrapperStyle}
				style={{
					position: stuckState ? 'sticky' : 'initial',
					// when we scroll down, the sticky header should be hidden and overlap with the navbar
					// this calculation is as follow stickyHeaderHeight - navbarHeight
					// i.e 72 - 56 = 16px
					top: stuckState === 'hidden' ? '-16px' : `${offsetHeight}px`,
				}}
				ref={this.containerRef}
				data-testid="with-sticky-header"
				data-vc="sticky-wrapper-css-container"
			>
				<DocumentScrollListener onBeforeExplicitScroll={this.ignoreNextScroll} />
				<AnimatedBox
					offsetHeight={offsetHeight}
					data-test-id="header-animated-box"
					data-vc="header-animated-box"
					style={{
						width: stuckState && width ? `${width}px` : 'inherit',
						animationName: stuckState === 'top' ? 'stickyAppear' : hiddenStateAnimation,
					}}
				>
					{this.cloneHeader()}
				</AnimatedBox>
				<WidthObserver setWidth={this.setWidth} offscreen />
			</div>
		);
	}
}
