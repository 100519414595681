import { useEffect, useState, useRef } from 'react';

import { isChrome, isSafari } from '@confluence/browser-helper';

import { getDynamicCoverImageHeight } from './coverPictureHelperFunctions';

export const usePageHeightWidthObserver = (
	isPagePreview: boolean,
	leftRightPaddingValueOffset: number,
	isPresenterMode?: boolean,
) => {
	const [height, setHeight] = useState<number>(0);
	const [width, setWidth] = useState<number>(0);
	const [leftOffset, setLeftOffset] = useState<number>(0);
	const observerRef = useRef<ResizeObserver | null>(null);

	useEffect(() => {
		let queryId;
		if (isPresenterMode) {
			queryId = "[id='presenter-mode-header-image-container']";
		} else if (isPagePreview) {
			queryId = "[id='header-row-wrapper']";
		} else {
			queryId = '.content-screen-component';
		}
		const pageScrollParent: HTMLElement | null = document.querySelector(queryId);
		if (pageScrollParent) {
			observerRef.current = new ResizeObserver((entries) => {
				// webkitbrowsers add a scroll padding that pushes content around
				if (isChrome() || isSafari()) {
					const innerWidth = Math.floor(entries[0]?.target.clientWidth);
					const outerWidth = Math.floor(entries[0]?.borderBoxSize?.[0].inlineSize);
					if (outerWidth > innerWidth) {
						setLeftOffset(outerWidth - innerWidth);
					}
				}

				const currentWidth =
					Math.floor(entries[0]?.target.clientWidth) - leftOffset - leftRightPaddingValueOffset;
				setWidth(currentWidth);
				setHeight(getDynamicCoverImageHeight(currentWidth));
			});
			observerRef.current.observe(pageScrollParent);

			return () => observerRef?.current?.disconnect();
		}
	}, [observerRef, leftOffset, isPagePreview, leftRightPaddingValueOffset, isPresenterMode]);

	return {
		height,
		width,
	};
};
