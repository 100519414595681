/** @jsx jsx */
import { css, jsx } from '@compiled/react';

import { WidthObserver } from '@atlaskit/width-detector';

import { getIsNav4Enabled } from '@confluence/nav4-enabled';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

const styles = css({
	position: 'relative',
	overflow: 'visible',
	zIndex: '13',
});

const popupPortalContainerId = 'highlight-actions-portal-container';
export { popupPortalContainerId };

let popupPortalContainerWidth;

function onResize(width) {
	// Hide popup when the container is resized.
	if (width !== popupPortalContainerWidth) {
		popupPortalContainerWidth = width;
		window.dispatchEvent(new CustomEvent('highlight-actions.container.resized'));
	}
}

export const HighlightActionsProvider = () => {
	return (
		<div css={styles} id={popupPortalContainerId}>
			<WidthObserver setWidth={onResize} />
		</div>
	);
};

/**
 *  In Nav4, we need to render the HighlightActionsProvider inside the #AkMainContent container instead of at the top of
 *  our app in <App />. This is because Nav4 uses a grid. So in Nav4 the window itself no longer scrolls; the #AkMainContent
 *  element does.
 *
 *  These HighlightActionsProviderForNav3 and HighlightActionsProviderForNav4 components allow us to render
 *  HighlightActions in different places depending on which nav is enabled.
 */

// Only renders the HighlightActionsProvider if we're using Nav3
export const HighlightActionsProviderForNav3 = () => {
	const renderServerPlaceholder = useRenderServerPlaceholder();
	// render null for first render during hydration to prevent mismatch
	if (renderServerPlaceholder) {
		return null;
	}
	return getIsNav4Enabled() ? null : <HighlightActionsProvider />;
};

// Only renders the Highlight Actions Provider if we're using Nav4
export const HighlightActionsProviderForNav4 = () => {
	const renderServerPlaceholder = useRenderServerPlaceholder();
	// render null for first render during hydration to prevent mismatch
	if (renderServerPlaceholder) {
		return null;
	}
	return getIsNav4Enabled() ? <HighlightActionsProvider /> : null;
};
