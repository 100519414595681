import type { FC } from 'react';
import React, { Fragment, useState, useCallback } from 'react';

import {
	EXTENSION_POINT_BYLINE_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { RequireLegacyWRM } from '@confluence/wrm';
import { FORGE_MODULE_BYLINE, LazyForgeUIExtensions } from '@confluence/forge-ui';
import {
	WebItemLocation,
	SYSTEM_CONTENT_BYLINE,
	type WebItemLocationChildrenFnWithLoading,
} from '@confluence/web-item-location';
import { ErrorDisplay } from '@confluence/error-boundary';
import { isErrorMarkedAsHandled } from '@confluence/graphql';

import { ByLineAppsComponent } from './ByLineAppsComponent';
import { ByLineAppsPlaceholder } from './ByLineAppsPlaceholder';
import { useByLineApps } from './useByLineApps';
import { LazyLivePagesByLineApps } from './LivePagesByLineApps';

type ByLineAppsProps = {
	contentId: string;
	showLivePagesExtensionPoints?: boolean;
};

export const LivePagesStyleByLineApps: FC<ByLineAppsProps> = ({ contentId }) => {
	const { hasApps, connectItems, forgeItems, forgeError } = useByLineApps(contentId);

	return hasApps ? (
		<LazyLivePagesByLineApps
			contentId={contentId}
			connectItems={connectItems}
			forgeItems={forgeItems}
			forgeError={forgeError}
			showLivePagesExtensionPoints
		/>
	) : null;
};

export const ByLineApps: FC<ByLineAppsProps> = ({ contentId, showLivePagesExtensionPoints }) => {
	const [showLoader, setShowLoader] = useState(true);
	const onLoad = useCallback(() => setShowLoader(false), []);

	return (
		<Fragment>
			<ExperienceStart name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
			{showLoader ? (
				<ByLineAppsPlaceholder
					key="byline-apps-loading"
					showLivePagesExtensionPoints={showLivePagesExtensionPoints}
				/>
			) : null}
			<RequireLegacyWRM
				key="byline-app-wrm"
				wrm="wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-content-byline-resources-v5"
			>
				{() => (
					<WebItemLocation
						tagName="span"
						contentId={contentId}
						location={SYSTEM_CONTENT_BYLINE}
						renderWhenLoading
					>
						{
							(({ webItems: connectItems, loading: loadingConnectApps }) => {
								if (loadingConnectApps) {
									// When loadingConnectApps changes from 'true' to 'false', it causes this function to re-run and
									// therefore re-mount the 'LazyForgeUIExtensions'. To avoid it we don't render 'LazyForgeUIExtensions'
									// while 'loadingConnectApps' is 'true'.
									// TODO(rtoropov): This will cause a delay rendering Forge apps since their load will trigger with
									//  first mount of 'LazyForgeUIExtensions'. Need an alternative way to fix a re-mount of the latter.
									return null;
								}
								return (
									<LazyForgeUIExtensions
										// Set 'key' as a contentId to force LazyForgeUIExtensions to be re-mounted when contentId changes.
										// This makes sure that old component state is not re-used for a new content id, because the list of
										// Forge extensions needs to be processed against display conditions that may depend on content
										// properties.
										key={contentId}
										moduleType={FORGE_MODULE_BYLINE}
										render={(forgeItems, loadingForgeApps, error) => {
											if (loadingForgeApps) return null;

											if (error) {
												if (!isErrorMarkedAsHandled(error)) {
													return <ErrorDisplay error={error} />;
												}
												// Process handled errors as successful
												return <ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />;
											}

											return (
												<Fragment>
													<ByLineAppsComponent
														contentId={contentId}
														forgeItems={forgeItems}
														connectItems={connectItems}
														onLoad={onLoad}
														showLivePagesExtensionPoints={showLivePagesExtensionPoints}
													/>
													<ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
												</Fragment>
											);
										}}
									/>
								);
							}) as WebItemLocationChildrenFnWithLoading
						}
					</WebItemLocation>
				)}
			</RequireLegacyWRM>
		</Fragment>
	);
};
