import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'inline-block',
	marginRight: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div({
	backgroundColor: token('color.skeleton'),
	width: '3px',
	height: '3px',
	borderRadius: '50%',
	display: 'inline-block',
	marginRight: token('space.075'),
	marginLeft: token('space.075'),
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	backgroundColor: token('color.skeleton'),
	width: '62px',
	height: '8px',
	borderRadius: '2px',
	display: 'inline-block',
});

export const ByLineAppsPlaceholder: FC<{
	showLivePagesExtensionPoints?: boolean;
}> = ({ showLivePagesExtensionPoints }) => (
	<Container data-testid="byline-apps-loading">
		{!showLivePagesExtensionPoints && <Circle data-testid="circle-placeholder" />}
		<Line />
	</Container>
);
