export const DEFAULT_POSITION_VALUE = 50;
export const REFERRAL_LINK = '?utm_source=Confluence&utm_medium=referral';
export const MIN_DYNAMIC_COVER_IMAGE_HEIGHT = 140;
export const MAX_DYNAMIC_COVER_IMAGE_HEIGHT = 750;
export const COVER_IMAGE_WIDTH_TO_HEIGHT_RATIO = 38 / 7;
export const CALCULATE_SCALED_COVER_IMAGE_HEIGHT =
	'min(max(calc(calc( 100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px) ) * (7 / 38)), 140px), 750px)';

export const COVER_IMAGE_FIXED_WIDTH = 800;
export const COVER_IMAGE_FIXED_HEIGHT = 147;
