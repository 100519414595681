import { useEffect, useState, useRef } from 'react';

type UseResizeObserverResponse = [React.MutableRefObject<HTMLDivElement | null>, number];
export const useWidthResizeObserver = (): UseResizeObserverResponse => {
	const [width, setWidth] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setWidth(entry.contentRect.width);
			});
		});

		if (observeTarget) {
			resizeObserver.observe(observeTarget);
			return () => {
				resizeObserver.unobserve(observeTarget);
			};
		} else {
			setWidth(0);
		}
	}, []);

	return [ref, width];
};
