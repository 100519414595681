import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classnames from 'classnames';

import { requireLegacyWRM } from '@confluence/wrm';

import { WebItemList } from './WebItemList';

// export for testing
export const CONNECT_WRM_MODULES = [
	'wr!confluence.web.resources:querystring',
	'wr!confluence.web.resources:navigator-context',
	'wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-resources-v5',
];

export const WebItemButtonList = (props) => {
	const {
		id,
		webItems,
		actions,
		modifications,
		buttonType,
		className,
		disableIcons,
		contentSubType,
		source,
	} = props;

	const needsConnectWRM = webItems.some((item) =>
		item.completeKey.startsWith('com.atlassian.plugins.atlassian-connect-plugin'),
	);

	useEffect(() => {
		if (needsConnectWRM) {
			requireLegacyWRM(
				CONNECT_WRM_MODULES,
				() => {
					// Do nothing after loaded
				},
				() => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.error(
							'Connect plugins in WebItemButtonList cannot be initialized when WRM is disabled.',
						);
					}
				},
			);
		}
	}, [needsConnectWRM]);

	return (
		<WebItemList
			dataTestId="web-item-button-list"
			id={id}
			actions={actions}
			modifications={modifications}
			webItems={webItems}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={classnames('aui-buttons', className)}
			webItemClassName={classnames(buttonType, 'aui-button')}
			disableIcons={disableIcons}
			contentSubType={contentSubType}
			source={source}
		/>
	);
};

WebItemButtonList.displayName = 'WebItemButtonList';
WebItemButtonList.propTypes = {
	id: PropTypes.string,
	webItems: PropTypes.array,
	actions: PropTypes.object,
	modifications: PropTypes.object,
	buttonType: PropTypes.string,
	className: PropTypes.string,
	disableIcons: PropTypes.bool,
	contentSubType: PropTypes.string,
	source: PropTypes.string,
};
