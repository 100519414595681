import { LoadableAfterPaint } from '@confluence/loadable';

export const ViewPageDistinctionBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewPageDistinctionBanner" */ '../src/view-page-distinction-banner/ViewPageDistinctionBanner'
			)
		).ViewPageDistinctionBanner,
});

export { useViewPageDistinctionBannerStore } from '../src/view-page-distinction-banner/ViewPageDistinctionBannerStore';
export { BANNER_HEIGHT as VIEW_PAGE_DISTINCTION_BANNER_HEIGHT } from '../src/view-page-distinction-banner/BannerSize';
