import type { FC, ComponentProps } from 'react';
import React, { Fragment, useEffect, useRef, useState, useContext, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { VIEW_PAGE_BYLINE_EXPERIENCE, ExperienceStart } from '@confluence/experience-tracker';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { PageCommentsCountContext } from '@confluence/comment-context';
import { PageSegmentLoadStart, PageSegmentLoadEnd } from '@confluence/browser-metrics';
import {
	ReloadType,
	useQuickReloadSubscriber,
} from '@confluence/quick-reload/entry-points/subscription';
import { usePageSpaceKey } from '@confluence/page-context';
import { ExternalShareContext } from '@confluence/external-share-context';
import { getTitleContentProperty } from '@confluence/content-topper';

import { BYLINE_METRIC } from '../perf.config';
import type { ContentHeaderUnifiedQuery as ContentHeaderUnifiedQueryType } from '../__types__/ContentHeaderUnifiedQuery';
import { ContentHeaderUnifiedQuery } from '../ContentHeaderUnifiedQuery.graphql';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from '../defaultQueryVariables';

import { ExternalByLineQuery } from './ExternalByLineQuery.graphql';
import { ExternalByLinePLInfoQuery } from './ExternalByLinePLInfoQuery.graphql';
import type { ExternalByLineQuery as ExternalByLineQueryType } from './__types__/ExternalByLineQuery';
import type { ExternalByLinePLInfoQuery as ExternalByLinePLInfoQueryType } from './__types__/ExternalByLinePLInfoQuery';
import type { QueryType, QueryVariablesType, QueryContentType } from './byLineTypes';
import { ByLineWithContributorsComponent } from './ByLineWithContributorsComponent';
import { ByLinePlaceholder } from './ByLinePlaceholder';
import { getSourceTemplateKey, isFabricEditor, toUserProps } from './byLineUtils';

// WARN: This is temporary code to send analytics event when we would crash the part
// of the ByLine experience when byline user is undefined due to any reason.
// This is done as part of https://ops.internal.atlassian.com/jira/browse/HOT-106424
// investigation. This code should be removed once we know the root cause/impact.
const HOT106424Tracker: FC<{
	content: QueryContentType | null | undefined;
	contentId: string;
	contentContributors: QueryType['contentContributors'];
	isExternalShareRequest: boolean;
}> = ({ content, contentId, contentContributors, isExternalShareRequest }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const byUser = content?.version?.by;
		const createdBy = content?.history?.createdBy;
		const hasEmptyContributors = (contentContributors?.nodes || []).some((node) => !node);
		const history = content?.history;
		const createdDate = content?.history?.createdDate;

		if (hasEmptyContributors || !createdBy || !byUser) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: 'byLine',
					action: 'byLineUserEmpty',
					actionSubject: 'content',
					attributes: {
						contentId,
						isExternalShareRequest,
						hasEmptyContributors,
						hasCreatedBy: Boolean(createdBy),
						hasByUser: Boolean(byUser),
						hasContent: Boolean(content),
						hasHistory: Boolean(history),
						hasCreatedDate: Boolean(createdDate),
					},
				},
			}).fire();
		}
	}, [content, contentId, contentContributors, createAnalyticsEvent, isExternalShareRequest]);

	return null;
};

type ByLineProps = {
	/**
	 * ID of the content to render
	 */
	contentId: string;
	/**
	 * Version to render
	 */
	versionOverride?: number | null;
	/**
	 * Control which version should be rendered for embedded content (attachments, "page-include" macro, etc.).
	 * 'version-at-save': Render the exact version when page was saved.
	 * 'current': Default. Render the latest version.
	 */
	embeddedContentRender?: string | null;
	/**
	 * Determines if we are using public link info for external query
	 */
	isUsingPublicLinkInfo?: boolean;
	/**
	 * Determines if we should display reactions in the byline
	 */
	shouldShowBylineReactions?: boolean;
	/**
	 * Content type to render
	 */
	contentType?: string | null;
	/**
	 * Content space id
	 */
	spaceId?: string | null;
	/**
	 * Content sub type to render
	 */
	contentSubType?: string | null;
};

type PassThroughProps = Pick<
	ComponentProps<typeof ByLineWithContributorsComponent>,
	'hasByLineContributors' | 'hasByLineExtensions'
>;

export const ByLine: FC<ByLineProps & PassThroughProps> = ({
	contentId,
	versionOverride,
	embeddedContentRender,
	isUsingPublicLinkInfo,
	shouldShowBylineReactions,
	contentType,
	spaceId,
	contentSubType,
	...passThroughProps
}) => {
	/**
	 * Initially, only request enough contributors to populate displayed
	 * avatars of the ByLineWithContributorsComponent's AvatarGroup.
	 * This is four at most; at five it shows three and a '+2' button.
	 * Additional contributors will be requested on-demand when hovering
	 * over/clicking its '+2' (or whatever) button.
	 *
	 * Additional Note: Please update the default limit in the relevant query to match
	 * DEFAULT_CONTRIBUTORS_FETCH_LIMIT. Preloader will not work as expected if these
	 * limits do not match.
	 */
	const [contributorsFetchLimit, setContributorsFetchLimit] = useState(
		DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
	);

	const [spaceKey] = usePageSpaceKey();
	const { count: commentCount } = useContext(PageCommentsCountContext);
	const { isExternalShareRequest } = useContext(ExternalShareContext);

	const lastLoadedQueryData = useRef<QueryType | null>(null);

	const fetchAllContributors = () => {
		if (lastLoadedQueryData.current) {
			// The lastLoadedQueryData would be from the initial request with the
			// fetch limit of 4
			setContributorsFetchLimit(
				lastLoadedQueryData.current?.contentContributors?.count || Number.MAX_SAFE_INTEGER,
			);
		}
	};

	const externalByLineQuery = isUsingPublicLinkInfo
		? ExternalByLinePLInfoQuery
		: ExternalByLineQuery;

	// eslint-disable-next-line prefer-const
	let { data, loading, error, refetch } = useQuery<
		QueryType,
		QueryVariablesType & { cacheBuster?: string }
	>(isExternalShareRequest ? externalByLineQuery : ContentHeaderUnifiedQuery, {
		variables: {
			contentId,
			spaceKey,
			// Don't omit versionOverride, embeddedContentRender and limit
			// It's an Apollo bug that if preloader these needs to be match preloaded query
			// Also doesn't work if removing them from preloader variables HOT-109035
			versionOverride: versionOverride || null,
			embeddedContentRender: embeddedContentRender || null,
			limit: contributorsFetchLimit,
			useNewContentTopper: true,
			publicLinkId: isUsingPublicLinkInfo ? contentId : undefined,
		},
		context: {
			allowOnExternalPage: true,
			single: true,
		},
	});

	const reload = useCallback(
		// Forcing refetch by passing dummy variable. Known Apollo bug (ノಠ益ಠ)ノ彡┻━┻, see page below:
		// https://pug.jira-dev.com/wiki/spaces/~683304838/pages/5971970200/Apollo+Client+-+weird+refetch+behavior
		// Reason using static cacheBuster: "":
		// ContentTitle component also makes ContentHeaderUnifiedQuery and refetch.
		// If using different cache buster values, the refetch in each component will make its owen refetch network call.
		// So setting a static value for this and ContentTitle component to make refetch network call only once.
		// And a static cache buster value can also force refetch to ignore cache of original query.
		() => refetch({ contentId, cacheBuster: '' }),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[refetch, contentId],
	);

	useQuickReloadSubscriber({
		name: 'byline',
		types: [ReloadType.content],
		reload,
	});

	const renderByLine = () => {
		if (loading) {
			// If we're loading because the ByLineWithContributorsComponent called our
			// fetchAllContributors, then we should continue the render with data
			// cached from the previous query (the one with the low
			// contributorsFetchLimit). It's important to not bring the skeletons back
			// up, as that'd kill the opening of the dropdown.
			if (lastLoadedQueryData.current) {
				data = lastLoadedQueryData.current;
			} else {
				return <ByLinePlaceholder />;
			}
		} else {
			if (!error) lastLoadedQueryData.current = data || null;
		}

		const externalData = isUsingPublicLinkInfo
			? (data as ExternalByLinePLInfoQueryType)?.publicLinkInformation
			: (data as ExternalByLineQueryType)?.singleContent;

		const content: QueryContentType | null | undefined = isExternalShareRequest
			? externalData
			: (data as ContentHeaderUnifiedQueryType)?.content?.nodes?.[0];

		if (error) {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
				return null;
			} else {
				// TODO: unknown errors should have a nicer UI within `<ErrorDisplay>`, but we don't have such UI for now
				// so returning empty `<ErrorDisplay>` here.
				return <ErrorDisplay error={error} />;
			}
		} else if (!content) {
			return null;
		}

		const author = toUserProps(content?.history?.createdBy);
		const owner =
			content?.history?.ownedBy && content?.type === 'page'
				? toUserProps(content?.history?.ownedBy)
				: null;
		const lastOwner =
			content?.history?.lastOwnedBy && content?.type === 'page'
				? toUserProps(content?.history?.lastOwnedBy)
				: null;
		const createdDateString = content?.history?.createdDate;
		const createdDate = createdDateString ? new Date(createdDateString) : null;
		const by = toUserProps(content?.version?.by);
		const versionComment = content?.version?.message || undefined;
		const contributors = data?.contentContributors?.nodes?.map(toUserProps) || [];
		const contributorsCount = data?.contentContributors?.count || 0;

		const templateId = !isExternalShareRequest ? getSourceTemplateKey(data) : null;
		const isFabricPage = !isExternalShareRequest ? isFabricEditor(data) : false;
		const { titleContentProperties } = getTitleContentProperty(data);

		const componentProps = {
			contentId,
			author,
			owner,
			createdDate,
			commentCount,
			contributors,
			contributorsCount,
			versionComment,
			fetchAllContributors,
			templateId,
			isFabricPage,
			lastOwner,
			shouldShowBylineReactions,
			contentSubType,
			spaceId,
			contentType,
			titleContentProperties,
			version: {
				friendlyWhen: content?.version?.friendlyWhen || '',
				number: Number(content?.version?.number || ''),
				by,
				syncRev: content?.version?.syncRev || 0,
				confRev: content?.version?.confRev || 0,
				contentTypeModified: content?.version?.contentTypeModified || false,
			},
			...passThroughProps,
		};
		return (
			<Fragment>
				<ByLineWithContributorsComponent {...componentProps} />
				{!loading && (
					<PageSegmentLoadEnd
						key={contentId}
						metric={BYLINE_METRIC}
						customData={{ contributorsCount }}
					/>
				)}
				<HOT106424Tracker
					content={content}
					contentId={contentId}
					contentContributors={data?.contentContributors}
					isExternalShareRequest={isExternalShareRequest}
				/>
			</Fragment>
		);
	};

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<ExperienceStart name={VIEW_PAGE_BYLINE_EXPERIENCE} id={contentId} />
			<PageSegmentLoadStart key={contentId} metric={BYLINE_METRIC} />
			{renderByLine()}
		</ErrorBoundary>
	);
};
