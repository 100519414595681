import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';

import { CommentButtonLazy } from './CommentButtonLazy';
import { CommentButtonPlaceholder } from './CommentButtonPlaceholder';
import { usePreloadCommentButton } from './usePreloadCommentButton';

type CommentButtonWrapperProps = {
	contentId: string;
	isFabricPage: boolean;
	isCommentButtonSSRd?: boolean;
};

const noop = () => {};

const SSR_ACTION_LOADING_SPINNER_STYLE = { padding: '0 1px' } as React.CSSProperties;

export const CommentButtonWrapper: FC<CommentButtonWrapperProps> = memo(
	({ contentId, isCommentButtonSSRd, isFabricPage }) => {
		const { data: commentData, error } = usePreloadCommentButton(contentId, isCommentButtonSSRd);

		const [showCommentButton, setShowCommentButton] = useState(false);

		/* Scenario: A user has clicked on the button in SSR -> SPA transition.
     The mouse click event is captured and the loading of CommentButtonLazy is started.
     */
		const handleCommentButtonClick = useCallback(
			(evt: any) => {
				const urlParams = new URLSearchParams(window.location.search);
				const hydrationParam = urlParams.has('FORCE_HYDRATE', '1');

				if (
					isCommentButtonSSRd &&
					!(fg('confluence_ssr_remove_action_button_clicks') || hydrationParam)
				) {
					if (!window.__SSR_EVENTS_CAPTURE__) {
						window.__SSR_EVENTS_CAPTURE__ = {};
					}

					window.__SSR_EVENTS_CAPTURE__.inlineCommentButton = evt;
				}

				setShowCommentButton(true);
			},
			[isCommentButtonSSRd],
		);

		/* Scenario: A user has clicked on the button in SSR.
     The mouse click event is captured and executed immediately on Placeholder component mount
     (ie. kickstarting the loading of the CommentButtonLazy component)
     */
		useEffect(() => {
			if (isCommentButtonSSRd && window.__SSR_EVENTS_CAPTURE__?.inlineCommentButton) {
				setShowCommentButton(true);
			}
		}, [isCommentButtonSSRd]);

		/*Conditional Loading of CommentButtonLazy if FF is ON and showCommentButton true (user click)*/

		/* in case of an error from CommentButtonQuery load CommentButtonLazy and allow CommentButton to make
          another network call to fetch data*/
		const isDataLoaded = Boolean(commentData && !error);

		if (showCommentButton || error) {
			return (
				<>
					<CommentButtonLazy
						contentId={contentId}
						isClickedInSSR={
							isCommentButtonSSRd && Boolean(window.__SSR_EVENTS_CAPTURE__?.inlineCommentButton)
						}
						isDataLoaded={isDataLoaded}
						isFabricPage={isFabricPage}
					/>
				</>
			);
		} else {
			return (
				<>
					{/* eslint-disable-next-line check-react-ssr-usage/no-react-ssr */}
					{process.env.REACT_SSR && (
						<SSRActionLoadingSpinner
							spinnerId="inline-comment-loading-spinner"
							actionType="inlineCommentButton"
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							styleOverride={SSR_ACTION_LOADING_SPINNER_STYLE}
						/>
					)}
					<CommentButtonPlaceholder
						isDisabled={!isDataLoaded && !isCommentButtonSSRd}
						handleCommentButtonClick={
							isDataLoaded || isCommentButtonSSRd ? handleCommentButtonClick : noop
						}
						contentId={contentId}
					/>
				</>
			);
		}
	},
);
