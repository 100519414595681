import React, { useEffect } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { useObjectSidebarActions } from '@confluence/object-sidebar-api';

import { useWidthResizeObserver } from './useWidthResizeObserver';

// the min-width should match the value of MIN_PANEL_WIDTH from '@confluence/object-sidebar-api'
const panelPushStyles = xcss({
	display: 'block',
	minWidth: '400px',
});

export const ObjectSidebarPushPlaceholder = () => {
	const { setPanelWidth } = useObjectSidebarActions();
	const [containerRef, width] = useWidthResizeObserver();

	useEffect(() => {
		setPanelWidth(width);
	}, [width, setPanelWidth]);

	return <Box ref={containerRef} xcss={panelPushStyles} testId="object-sidebar-push-placeholder" />;
};
