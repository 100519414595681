import React, { useRef, type FC } from 'react';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead

import { Subscribe } from 'unstated';

import { token } from '@atlaskit/tokens';

import { BannerStateContainer } from '@confluence/banners';

import { ContentScreenBackgroundLayer } from './ContentScreenBackgroundLayer';

export const DEFAULT_SCREEN_STYLES = {
	position: 'relative',
	background: token('elevation.surface'),
	paddingTop: '0',
	paddingRight: token('space.500'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.500'),
	minHeight: 'calc(100vh - 56px)',
	boxSizing: 'border-box',
	// added in for cover image
	'--full-cover-image-margin': '-40px', // should be the negative value of the padding of the container
	'--full-cover-image-width': 'calc(100% + 80px)', // should do calculations based on the padding of the container
};

export const LOADING_SCREEN_STYLES = {
	position: 'fixed',
	width: '100%',
	height: '100%',
	top: '0',
	display: 'none',
	bottom: '0',
	left: '0',
	right: '0',
	zIndex: '10',
	background: token('color.skeleton'),
};

const LIVE_PAGES_SCREEN_STYLES = {
	paddingTop: '0px',
	paddingRight: '0px',
	paddingBottom: '0px',
	paddingLeft: '0px',
	minHeight: 'unset',
	// added in for cover image
	'--full-cover-image-margin': '0px', // should be the negative value of the padding of the container
	'--full-cover-image-width': '100%', // should do calculations based on the padding of the container
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowHiddenDiv = styled.div({
	overflow: 'hidden',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PositionRelativeDiv = styled.div({
	position: 'relative',
});

const DEFAULT_LOOK_AND_FEEL = {
	content: {
		screen: DEFAULT_SCREEN_STYLES,
	},
};

const EMPTY_CONTENT_SCREEN_STYLES = {};

function convertGutters(customScreenStyles) {
	if (customScreenStyles.gutterLeft === 'none') {
		customScreenStyles.paddingLeft = '0';
	}
	if (customScreenStyles.gutterRight === 'none') {
		customScreenStyles.paddingRight = '0';
	}
	if (customScreenStyles.gutterTop === '0px') {
		customScreenStyles.paddingTop = '0';
	}
	if (customScreenStyles.gutterBottom === '0px') {
		customScreenStyles.paddingBottom = '0';
	}

	return customScreenStyles;
}

function getStylesFromLookAndFeel(lookAndFeel) {
	if (!lookAndFeel || !lookAndFeel.content) {
		return {};
	}

	const screenLookAndFeel = convertGutters({
		...lookAndFeel.content.screen,
	});
	delete screenLookAndFeel.layer;

	return screenLookAndFeel;
}

function adjustMinHeight(styles, bannerHeight) {
	// Update the minHeight style only if a banner is being shown and the minHeight is "100vh" (aka full height)
	const updatedStyles = { ...styles };
	if (updatedStyles.minHeight === '100vh' && bannerHeight > 0) {
		updatedStyles.minHeight = `calc(${updatedStyles.minHeight} - ${bannerHeight}px)`;
	}
	return updatedStyles;
}

export const ContentScreenComponent: FC<{
	spaceKey?: string;
	children: React.ReactNode;
	lookAndFeel?: any;
	// This prop contains react-css values to customize the default content screen styles according to the consumer's need.
	contentScreenStyles?: any;
	isThemed?: boolean;
	isContentView?: boolean;
	isLoading?: boolean;
	isLivePage?: boolean;
}> = ({
	spaceKey,
	children,
	lookAndFeel = DEFAULT_LOOK_AND_FEEL,
	// This prop contains react-css values to customize the default content screen styles according to the consumer's need.
	contentScreenStyles = EMPTY_CONTENT_SCREEN_STYLES,
	isThemed = false,
	isContentView = false,
	isLoading = false,
	isLivePage = false,
}) => {
	const previousStyleState = useRef<{
		getStyles: (live: boolean) => Record<string, string>;
		spaceKey: string | undefined;
	} | null>(null);
	const shouldApplyTheme = isThemed || isContentView;

	let styles: Record<string, string>;
	// 99.9% of time we should have lookAndFeel preloaded on SSR
	if (isLoading) {
		const previous = previousStyleState.current;

		// In transition since style in the same space should be the same
		// We can optimistically use the same style as the previous one
		if (spaceKey && spaceKey === previous?.spaceKey) {
			styles = previous?.getStyles(isLivePage) || LOADING_SCREEN_STYLES;
		} else {
			styles = LOADING_SCREEN_STYLES;
		}
	} else {
		const getStyles = shouldApplyTheme
			? (live: boolean) => ({
					...DEFAULT_SCREEN_STYLES,
					...(live ? LIVE_PAGES_SCREEN_STYLES : {}),
					...contentScreenStyles,
					...getStylesFromLookAndFeel(lookAndFeel),
				})
			: () => ({});
		previousStyleState.current = { getStyles, spaceKey };
		styles = getStyles(isLivePage);
	}

	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				const bannerHeight = bannerState.getTotalHeight();
				const updatedStyles = adjustMinHeight(styles, bannerHeight);

				return (
					<div
						data-testid="content-screen-component"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="content-screen-component"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={updatedStyles}
					>
						{shouldApplyTheme ? (
							<>
								<ContentScreenBackgroundLayer lookAndFeel={lookAndFeel} />
								{/**
								 * For pages created by fabric we need to know the width of the content
								 * so we can use that to size breakout images/tables properly.
								 * This css class can be removed when Confluence adopts the Fabric renderer.
								 */}
								<PositionRelativeDiv>
									{/* The next div is a hack to make sure lower elements with a margin have something to push against */}
									<OverflowHiddenDiv />
									{children}
								</PositionRelativeDiv>
							</>
						) : (
							<>{children}</>
						)}
					</div>
				);
			}}
		</Subscribe>
	);
};
