import type { FC } from 'react';
import React, { memo, useMemo, useCallback, useContext, Fragment } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AkAvatarGroup from '@atlaskit/avatar-group';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';
import { VIEW_PAGE_BYLINE_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ProfileAvatar, ProfileLink, ProfileCardWrapper } from '@confluence/profile';
import { ReadTime } from '@confluence/read-time';
import { AnalyticsByLine } from '@confluence/confluence-analytics';
import { ExternalShareContext } from '@confluence/external-share-context';
import { scrollPageCommentIntoView } from '@confluence/comments-util';
import { PoweredByTemplates } from '@confluence/powered-by-templates';
import { PageOwnershipByline, BadgeStylePageOwnershipByline } from '@confluence/content-ownership';
import { AutoConversionByLine } from '@confluence/editor-conversion/entry-points/AutoConversionByLine';
import { TitleAlignmentType, type TitleContentPropertiesType } from '@confluence/content-topper';
import { expVal, expValEquals } from '@confluence/feature-experiments';
import { RendererContentState } from '@confluence/content-state';
import { ViewPageBylineReactions } from '@confluence/reactions/entry-points/ViewPageBylineReactions';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { ByLineApps, LivePagesStyleByLineApps } from './ByLineApps';
import { ByLineLastModified } from './ByLineLastModified';
import type { UserShape } from './byLineTypes';
import { getArrangedContributors } from './byLineUtils';
import { png_1x1_n40 } from './png1x1';
import { SingleLineByLine } from './SingleLineByLine';
import {
	StyledMainDiv,
	StyledAuthorDiv,
	StyledAvatars,
	StyledNameSkeleton,
	StyledTextDiv,
	CommentCountContainer,
} from './PresentationalComponents';
import { ByLineLastUpdatedCompact } from './ByLineLastUpdatedCompact';
import { BylineClassification } from './BylineClassification';

const BYLINE_EXPERIMENT_NO_OF_AVATARS = 3;
const NO_OF_AVATARS = 4;

const BLOG_POST_SOURCE_TEMPLATE_ENTITY_ID = '00000000-0000-0000-0000-000000000001';

type version = {
	friendlyWhen: string;
	number: number;
	by: UserShape;
	contentTypeModified: boolean;
};

type ByLineProps = {
	contentId: string;
	author: UserShape;
	owner: UserShape | null;
	lastOwner: UserShape | null;
	createdDate: Date | null;
	commentCount: number;
	version: version;
	contributors: UserShape[];
	contributorsCount: number;
	fetchAllContributors: Function;
	versionComment?: string;
	hasByLineContributors?: boolean;
	hasByLineExtensions?: boolean;
	templateId: string | null;
	isFabricPage: boolean;
	titleContentProperties: TitleContentPropertiesType;
	shouldShowBylineReactions?: boolean;
	contentType?: string | null;
	spaceId?: string | null;
	contentSubType?: string | null;
};

type userAvatarShape = {
	isAnonymous: boolean;
	userId: string;
	src: string;
	name: string;
};

const i18nBase = defineMessages({
	commentCount: {
		id: 'content-header.by-line.comment.count',
		defaultMessage: '{count, plural, one {# page comment} other {# page comments}}',
	},
});

const i18nLiveDocs = defineMessages({
	commentCount: {
		id: 'content-header.by-line.comment.count.livedocs',
		defaultMessage: '{count, plural, one {# general comment} other {# general comments}}',
		description: 'Updated version of content-header.by-line.comment.count',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

const newRendererBylineExperimentMainlineStyles = xcss({
	alignItems: 'center',
	whiteSpace: 'nowrap',
});

const newRendererBylineExperimentCenteredTitleStyles = xcss({
	justifyContent: 'center',
	marginBottom: 'space.025',
});

const newRendererBylineLastUpdatedStyles = xcss({
	marginTop: 'space.negative.025',
	marginLeft: 'space.negative.050',
	marginRight: 'space.negative.075',
});

const bylineAppsStyles = xcss({
	marginTop: 'space.100',
	font: 'font.body.small',
	display: 'inline',
});

const fullWidthStyles = xcss({
	width: '100%',
});

const alignItemsCenterStyles = xcss({
	alignItems: 'center',
});

const NewRendererBylineExperiment = ({
	showByLineContributors,
	isTitleCenterAligned,
	renderOwnerAvatar,
	CreatorOrOwnerByline,
	version,
	contentId,
	isPageCommentNavigationCTAEnabled,
	commentCount,
	handleClick,
	showByLineExtensions,
	isExternalShareRequest,
	shouldShowBylineReactions,
	contentType,
	contentSubType,
	spaceId,
	owner,
	author,
	isFabricPage,
}) => {
	const commonContent = (
		<>
			{showByLineContributors && (
				<>
					{!fg('cc_page_experiences_byline_badge_style') ? (
						<Inline testId="renderer-new-byline-experiment-contributors">
							<StyledAuthorDiv isTitleCenterAligned={isTitleCenterAligned}>
								{isTitleCenterAligned && renderOwnerAvatar}
								{CreatorOrOwnerByline}
							</StyledAuthorDiv>
						</Inline>
					) : (
						<BadgeStylePageOwnershipByline
							owner={owner}
							author={author}
							analyticsSource="renderer"
						/>
					)}
					{fg('cc_page_experiences_byline_classification') && (
						<BylineClassification contentId={contentId} isReadOnly />
					)}
					<RendererContentState contentId={contentId} isDraft={false} hasSeparator={false} />
					{((!isFabricPage && fg('confluence_frontend_object_header')) ||
						!fg('confluence_frontend_object_header')) && (
						<Box xcss={newRendererBylineLastUpdatedStyles}>
							<ByLineLastUpdatedCompact version={version} />
						</Box>
					)}
				</>
			)}
			{isPageCommentNavigationCTAEnabled && commentCount > 0 && (
				<CommentCountContainer onClick={handleClick}>
					<FormattedMessage
						{...i18n.commentCount}
						values={{
							count: commentCount,
						}}
					/>
				</CommentCountContainer>
			)}
			{showByLineExtensions && (
				<>
					<ReadTime contentId={contentId} />
					<AnalyticsByLine contentId={contentId} contentAuthorId={author.userId} />
					{shouldShowBylineReactions && (
						<ViewPageBylineReactions
							contentId={contentId}
							contentType={contentType}
							spaceId={spaceId}
							contentSubType={contentSubType}
							noFlexWrap={fg('confluence_frontend_single_line_byline')}
							noRelativeContainer={fg('confluence_frontend_single_line_byline')}
						/>
					)}
					{!isExternalShareRequest && <AutoConversionByLine />}
				</>
			)}
		</>
	);

	if (fg('confluence_frontend_single_line_byline')) {
		return (
			<StyledMainDiv
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
				className="page-metadata-modification-info"
				isTitleCenterAligned={isTitleCenterAligned}
			>
				<StyledTextDiv
					hasBadgeStylePageOwnerBylineItem={fg('cc_page_experiences_byline_badge_style')}
				>
					<SingleLineByLine
						contentId={contentId}
						isTitleCenterAlignedOverride={isTitleCenterAligned}
					>
						{showByLineContributors &&
							!isTitleCenterAligned &&
							!fg('cc_page_experiences_byline_badge_style') &&
							renderOwnerAvatar}
						{commonContent}
					</SingleLineByLine>
				</StyledTextDiv>
			</StyledMainDiv>
		);
	}

	return (
		<StyledMainDiv
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className="page-metadata-modification-info"
			data-testid="page-main-div"
			isTitleCenterAligned={isTitleCenterAligned}
		>
			<Flex direction="column" xcss={fullWidthStyles}>
				<Flex xcss={alignItemsCenterStyles}>
					{showByLineContributors &&
						!isTitleCenterAligned &&
						!fg('cc_page_experiences_byline_badge_style') &&
						renderOwnerAvatar}
					<StyledTextDiv
						hasBadgeStylePageOwnerBylineItem={fg('cc_page_experiences_byline_badge_style')}
					>
						<Flex
							xcss={[
								newRendererBylineExperimentMainlineStyles,
								isTitleCenterAligned && newRendererBylineExperimentCenteredTitleStyles,
							]}
							gap="space.075"
							testId="renderer-new-byline-mainline"
						>
							{commonContent}
						</Flex>
					</StyledTextDiv>
				</Flex>
				{showByLineExtensions && !isExternalShareRequest && (
					<Flex alignItems="center" xcss={bylineAppsStyles}>
						<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
							<LivePagesStyleByLineApps contentId={contentId} />
						</ErrorBoundary>
					</Flex>
				)}
			</Flex>
			<ExperienceSuccess name={VIEW_PAGE_BYLINE_EXPERIENCE} />
		</StyledMainDiv>
	);
};

const DefaultRendererByline = ({
	showByLineContributors,
	isTitleCenterAligned,
	renderAvatarGroup,
	CreatorOrOwnerByline,
	version,
	author,
	contentId,
	versionComment,
	isPageCommentNavigationCTAEnabled,
	commentCount,
	handleClick,
	showByLineExtensions,
	isExternalShareRequest,
}) => (
	<StyledMainDiv
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		className="page-metadata-modification-info"
		data-testid="page-main-div"
		isTitleCenterAligned={isTitleCenterAligned}
	>
		{showByLineContributors && !isTitleCenterAligned && renderAvatarGroup}
		<StyledTextDiv>
			{showByLineContributors && (
				<StyledAuthorDiv isTitleCenterAligned={isTitleCenterAligned}>
					{isTitleCenterAligned && renderAvatarGroup}
					{CreatorOrOwnerByline}
				</StyledAuthorDiv>
			)}
			<div>
				{showByLineContributors && (
					<ByLineLastModified
						version={version}
						author={author}
						contentId={contentId}
						versionComment={versionComment}
					/>
				)}
				{isPageCommentNavigationCTAEnabled && commentCount > 0 && (
					<CommentCountContainer onClick={handleClick}>
						<FormattedMessage
							{...i18n.commentCount}
							values={{
								count: commentCount,
							}}
						/>
					</CommentCountContainer>
				)}
				{showByLineExtensions && (
					<Fragment>
						<ReadTime contentId={contentId} />
						<AnalyticsByLine contentId={contentId} contentAuthorId={author.userId} />
						{!isExternalShareRequest && (
							<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
								<ByLineApps contentId={contentId} />
							</ErrorBoundary>
						)}
						{!isExternalShareRequest && <AutoConversionByLine />}
					</Fragment>
				)}
			</div>
		</StyledTextDiv>
		<ExperienceSuccess name={VIEW_PAGE_BYLINE_EXPERIENCE} />
	</StyledMainDiv>
);

export const ByLineWithContributorsComponent: FC<ByLineProps> = memo(
	({
		contentId,
		author,
		owner,
		createdDate,
		commentCount,
		version,
		versionComment,
		contributors,
		contributorsCount,
		fetchAllContributors,
		hasByLineContributors,
		hasByLineExtensions,
		templateId,
		isFabricPage,
		lastOwner,
		titleContentProperties,
		shouldShowBylineReactions,
		contentType,
		contentSubType,
		spaceId,
	}) => {
		const isPageCommentNavigationCTAEnabled =
			expVal<'control' | 'variation'>('cc-page-comments-quick-navigation', 'cohort', 'control') !==
			'control';
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isExternalShareRequest } = useContext(ExternalShareContext);

		const isTitleCenterAligned =
			titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

		const getContributorsData = useMemo(() => {
			const lastModifier: UserShape = version.by;

			const arrangedContributors: UserShape[] = getArrangedContributors({
				owner,
				author,
				lastModifier,
				contributors,
			});

			const userToAvatarGroupDataItem: (user) => userAvatarShape = (user) => ({
				// Props for <ProfileAvatar>
				isAnonymous: user.isAnonymous,
				userId: user.userId,
				src: user.avatarUrl,
				// Extra props for <AkAvatarGroup>
				name: user.fullName,
			});

			return [
				...arrangedContributors
					// Actual data for the users we know about
					.map(userToAvatarGroupDataItem),
				// Skeletons for the ones we don't
				...Array(Math.max(contributorsCount - arrangedContributors.length, 0))
					.fill(null)
					.map(() => ({
						src: png_1x1_n40,
						name: <StyledNameSkeleton />,
					})),
			];
		}, [contributorsCount, version, author, contributors, owner]);

		const allContributorsMayBeNeeded = useCallback(() => {
			if (contributorsCount === contributors.length) return;
			if (!fetchAllContributors) return;

			// Analytics event is for measuring how many prefetches may be wasteful.
			// Only fire 'dataPrefetched' if we actually start a prefetch
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'dataPrefetched',
					actionSubject: 'moreButton',
					source: 'bylineContributors',
				},
			}).fire();

			fetchAllContributors();
		}, [createAnalyticsEvent, fetchAllContributors, contributors, contributorsCount]);

		const allContributorsAreNeeded = useCallback(() => {
			// Analytics event is for measuring how many prefetches may be wasteful.
			// Always fire 'dataNeeded' when a click/tap on the +x button happened,
			// regardless of whether we still need to fetch more data.
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'dataNeeded',
					actionSubject: 'moreButton',
					source: 'bylineContributors',
				},
			}).fire();

			if (contributorsCount === contributors.length) return;
			if (!fetchAllContributors) return;

			fetchAllContributors();
		}, [createAnalyticsEvent, fetchAllContributors, contributors, contributorsCount]);

		const showMoreButtonProps = useMemo(
			() => ({
				onMouseEnter: allContributorsMayBeNeeded,
				onClick: allContributorsAreNeeded,
			}),
			[allContributorsAreNeeded, allContributorsMayBeNeeded],
		);

		const isBlogPost = templateId === BLOG_POST_SOURCE_TEMPLATE_ENTITY_ID;

		const showByLinePoweredByTemplates = Boolean(
			!isExternalShareRequest && isFabricPage && !isBlogPost && templateId,
		);
		const showByLineContributors = hasByLineContributors ?? true;
		const showByLineExtensions = hasByLineExtensions ?? true;

		const CreatorOrOwnerByline = useMemo(() => {
			if (owner !== null) {
				return (
					<PageOwnershipByline
						owner={owner}
						creator={author}
						createdDate={createdDate}
						templateId={showByLinePoweredByTemplates ? templateId : null}
						contentId={contentId}
						lastOwner={lastOwner}
					/>
				);
			} else {
				const linkOnly = (
					<ProfileLink
						userId={author.userId}
						fullName={author.fullName}
						isAnonymous={author.isAnonymous}
						referralSource="profilecard"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						style={{ textDecorationColor: token('color.link') }}
					/>
				);
				const profileLink = fg('confluence_show_profile_card_on_name_hover') ? (
					<ProfileCardWrapper userId={author.userId}>{linkOnly}</ProfileCardWrapper>
				) : (
					linkOnly
				);

				if (expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')) {
					return (
						<FormattedMessage
							id="content-header.by-line.abbreviated.created.by.expanded"
							defaultMessage="{showByLinePoweredByTemplates, select, true {By {author}, {withTemplate}} false {By {author}} other {By {author}}}"
							description="Abbreviated text to show author details as well as if a page was created with a template"
							values={{
								author: profileLink,
								withTemplate:
									showByLinePoweredByTemplates && !!templateId ? (
										<PoweredByTemplates templateId={templateId} />
									) : (
										''
									),
								showByLinePoweredByTemplates,
							}}
						/>
					);
				}

				return (
					<FormattedMessage
						id="content-header.by-line.created.by.expanded"
						defaultMessage="{showByLinePoweredByTemplates, select, true {Created by {author}, {withTemplate}} false {Created by {author}} other {Created by {author}}}"
						description="Text to show author details as well as if a page was created with a template"
						values={{
							author: profileLink,
							withTemplate:
								showByLinePoweredByTemplates && !!templateId ? (
									<PoweredByTemplates templateId={templateId} />
								) : (
									''
								),
							showByLinePoweredByTemplates,
						}}
					/>
				);
			}
		}, [
			author,
			owner,
			createdDate,
			templateId,
			showByLinePoweredByTemplates,
			contentId,
			lastOwner,
		]);

		const renderOwnerAvatar = useMemo(() => {
			const pageOwnerOrFirstContributorData = getContributorsData[0] as userAvatarShape;

			return (
				<StyledAvatars isTitleCenterAligned={isTitleCenterAligned}>
					<ProfileAvatar
						src={pageOwnerOrFirstContributorData.src}
						userId={pageOwnerOrFirstContributorData.userId}
						isAnonymous={pageOwnerOrFirstContributorData.isAnonymous}
						name={pageOwnerOrFirstContributorData.name}
						size="small"
						testId="renderer-byline-owner-or-first-contributor-avatar"
					/>
				</StyledAvatars>
			);
		}, [getContributorsData, isTitleCenterAligned]);

		const renderAvatarGroup = useMemo(() => {
			return (
				<StyledAvatars isTitleCenterAligned={isTitleCenterAligned}>
					<AkAvatarGroup
						appearance="stack"
						// @ts-ignore
						data={getContributorsData}
						avatar={ProfileAvatar}
						maxCount={
							expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
								? BYLINE_EXPERIMENT_NO_OF_AVATARS
								: NO_OF_AVATARS
						}
						size={
							isTitleCenterAligned ||
							expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
								? 'small'
								: 'medium'
						}
						showMoreButtonProps={showMoreButtonProps}
						testId="renderer-byline-contributors"
					/>
				</StyledAvatars>
			);
		}, [getContributorsData, isTitleCenterAligned, showMoreButtonProps]);

		const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			const commentElem = document.querySelector(
				'[data-testid="comments-count"]',
			) as HTMLElement | null;

			if (commentElem) {
				// Used temporarily for the experiment cc-page-comments-quick-navigation
				scrollPageCommentIntoView(commentElem, false, false);
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'clicked',
						actionSubject: 'bylinePageCommentCTA',
						source: 'ByLineWithContributorsComponent',
					},
				}).fire();
			}
		};

		return expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test') ? (
			<NewRendererBylineExperiment
				contentId={contentId}
				commentCount={commentCount}
				version={version}
				showByLineContributors={showByLineContributors}
				showByLineExtensions={showByLineExtensions}
				isPageCommentNavigationCTAEnabled={isPageCommentNavigationCTAEnabled}
				isExternalShareRequest={isExternalShareRequest}
				isTitleCenterAligned={isTitleCenterAligned}
				renderOwnerAvatar={renderOwnerAvatar}
				CreatorOrOwnerByline={CreatorOrOwnerByline}
				handleClick={handleClick}
				shouldShowBylineReactions={shouldShowBylineReactions}
				contentType={contentType}
				contentSubType={contentSubType}
				spaceId={spaceId}
				owner={owner}
				author={author}
				isFabricPage={isFabricPage}
			/>
		) : (
			<DefaultRendererByline
				contentId={contentId}
				author={author}
				commentCount={commentCount}
				version={version}
				versionComment={versionComment}
				showByLineContributors={showByLineContributors}
				showByLineExtensions={showByLineExtensions}
				isPageCommentNavigationCTAEnabled={isPageCommentNavigationCTAEnabled}
				isExternalShareRequest={isExternalShareRequest}
				isTitleCenterAligned={isTitleCenterAligned}
				renderAvatarGroup={renderAvatarGroup}
				CreatorOrOwnerByline={CreatorOrOwnerByline}
				handleClick={handleClick}
			/>
		);
	},
);
