/* eslint-disable @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values */
/** @jsx jsx */
/** @jsxFrag */
import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { ReactComponentLike } from 'prop-types';
import { styled, css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';

import { VIEW_PAGE_TITLE_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import {
	EMOJI_SIZE_LARGE,
	EMOJI_SIZE_MEDIUM,
} from '@confluence/emoji-title/entry-points/emojiTitleConstants';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
	type TitleContentPropertiesType,
	COVERPICTUREWIDTH,
} from '@confluence/content-topper';
import { CopyLinkButtonLoader } from '@confluence/share';
import { fg } from '@confluence/feature-gating';
import {
	RedactionsTourSteps,
	RedactionTourStepSource,
	REDACTIONS_TOUR_TARGETS,
	useRedactionsTour,
	useTrackTitleRedactionRendered,
} from '@confluence/redactions/entry-points/tour';

import { CONTENT_TITLE_METRIC } from '../perf.config';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const HeadingWrapper = styled.div<{
	hasBanner?: boolean;
}>({
	position: 'relative',
	marginBottom: ({ hasBanner }) => (hasBanner ? token('space.300') : 'initial'),
});

// TODO: Refactor to a common location
// See https://product-fabric.atlassian.net/browse/CFE-2847

/* please update next/packages/content-title/src/ContentTitlePlaceholder.js if you change anything here */
const styledContentTitle = css({
	display: 'inline-block',
	boxSizing: 'border-box',
	minHeight: '120px',
	width: '100%',
	maxWidth: '100%',
});

const centerTitleStyles = css({
	display: 'block',
	margin: '0 auto',
	wordBreak: 'break-word',
	wordWrap: 'break-word',
	maxWidth: '760px',
});

const getMarginTopStyledH1 = (isEmbeddedPage, hasCoverPicture, isObjectHeaderEnabled, hasEmoji) => {
	if (isObjectHeaderEnabled) {
		return isEmbeddedPage || (hasCoverPicture && hasEmoji) ? '0px' : '72px';
	}

	return isEmbeddedPage && hasCoverPicture ? token('space.negative.300') : '0px';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledH1 = styled.h1<{
	onClick: React.ComponentProps<'h1'>['onClick'];
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	isPageContentFullWidth?: boolean;
	isEmbeddedPage?: boolean;
	hasEmoji?: boolean;
	isFixedWidthImageOption?: boolean;
	isObjectHeaderEnabled?: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&#title-text': {
			alignItems: 'center',
			font: token('font.heading.xlarge'),
			fontWeight: token('font.weight.regular'),
			flexDirection: ({
				isFixedWidthImageOption,
				hasEmoji,
				isPageContentFullWidth,
				hasCoverPicture,
				isTitleCenterAligned,
			}) =>
				isTitleCenterAligned ||
				(isFixedWidthImageOption &&
					hasEmoji &&
					!isTitleCenterAligned &&
					isPageContentFullWidth &&
					hasCoverPicture)
					? 'column'
					: 'unset',
			marginTop: ({ isEmbeddedPage, hasCoverPicture, isObjectHeaderEnabled, hasEmoji }) =>
				getMarginTopStyledH1(isEmbeddedPage, hasCoverPicture, isObjectHeaderEnabled, hasEmoji),
			gap: ({ isTitleCenterAligned, hasCoverPicture }) =>
				isTitleCenterAligned && !hasCoverPicture ? token('space.200') : 'initial',
		},
		margin: '0',
		color: token('color.text'),
		wordBreak: 'break-word',
		wordWrap: 'break-word',
		whiteSpace: 'preserve',
		/* update next/packages/content-title/src/ContentTitlePlaceholder.js when this changes */
		minHeight: '35px',

		paddingTop: ({ isObjectHeaderEnabled }) => (isObjectHeaderEnabled ? '0px' : '5px'),
		cursor: 'pointer',
		display: 'flex',
		pointerEvents: ({ onClick }) => (onClick ? 'default' : 'none'),
	},
	({ isTitleCenterAligned }) =>
		isTitleCenterAligned
			? 'justify-content: center; text-align: center; flex-direction: column; align-items: center'
			: '',
);

const emojiFullWidthLeftAlignWrapper = css({
	display: 'flex',
	justifyContent: 'initial',
	width: '800px',
	paddingLeft: token('space.500'),
});

const EmojiAlignmentWrapper = ({ children, isLeftAlignedTitleWithFixedWidthImageAndEmoji }) => (
	<>
		{isLeftAlignedTitleWithFixedWidthImageAndEmoji ? (
			<div css={emojiFullWidthLeftAlignWrapper} data-testid="emoji-alignment-wrapper">
				{children}
			</div>
		) : (
			<>{children}</>
		)}
	</>
);

const titleTextFullWidthLeftAlignWrapper = css({
	display: 'flex',
	justifyContent: 'flex-start',
	width: '100%',
});

const TitleTextAlignmentWrapper = ({ children, isLeftAlignedTitleWithFixedWidthImageAndEmoji }) => (
	<>
		{isLeftAlignedTitleWithFixedWidthImageAndEmoji ? (
			<div css={titleTextFullWidthLeftAlignWrapper} data-testid="title-alignment-wrapper">
				{children}
			</div>
		) : (
			<>{children}</>
		)}
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const EmojiWrapper = styled.div<{
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	isObjectHeaderEnabled?: boolean;
}>({
	paddingRight: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? '0px' : '10px'),
	display: 'flex',
	justifyContent: 'center',
	maxWidth: ({ hasCoverPicture }) =>
		hasCoverPicture ? `${EMOJI_SIZE_LARGE}px` : `${EMOJI_SIZE_MEDIUM}px`,
	alignItems: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'initial' : 'center'),
	position: ({ hasCoverPicture }) => (hasCoverPicture ? 'absolute' : 'unset'),
	top: ({ hasCoverPicture, isObjectHeaderEnabled }) =>
		hasCoverPicture && isObjectHeaderEnabled
			? '-40px'
			: hasCoverPicture && !isObjectHeaderEnabled
				? '-75px'
				: 'unset',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> * span': {
		display: 'flex',
		justifyContent: 'center',
		width: ({ hasCoverPicture }) =>
			hasCoverPicture ? `${EMOJI_SIZE_LARGE}px` : `${EMOJI_SIZE_MEDIUM}px`,
		height: ({ hasCoverPicture }) =>
			hasCoverPicture ? `${EMOJI_SIZE_LARGE}px` : `${EMOJI_SIZE_MEDIUM}px`,
		overflow: 'hidden',
		alignItems: 'center',
	},
});

const titleCenteredMargin = (isTitleCenterAligned) => {
	return isTitleCenterAligned ? '-5px' : 'unset';
};

const getMarginTopTitleTextWrapper = (
	isTitleCenterAligned,
	hasCoverPicture,
	isObjectHeaderEnabled,
	hasEmoji,
	isEmbeddedPage,
) => {
	if (isEmbeddedPage) {
		return hasCoverPicture ? '50px' : '0px';
	}

	return hasCoverPicture && hasEmoji && isObjectHeaderEnabled
		? '96px'
		: !isObjectHeaderEnabled && hasCoverPicture
			? '26px'
			: titleCenteredMargin(isTitleCenterAligned);
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TitleTextWrapper = styled.span<{
	isEmbeddedPage?: boolean;
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	isObjectHeaderEnabled?: boolean;
	hasEmoji?: boolean;
}>({
	marginTop: ({
		isTitleCenterAligned,
		hasCoverPicture,
		isObjectHeaderEnabled,
		hasEmoji,
		isEmbeddedPage,
	}) =>
		getMarginTopTitleTextWrapper(
			isTitleCenterAligned,
			hasCoverPicture,
			isObjectHeaderEnabled,
			hasEmoji,
			isEmbeddedPage,
		),
	/* Slide out and show "copy link" button next to title on hover */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > span, &:focus-within > span': {
		opacity: '1',
		transform: 'none',
		transformDuration: '0.1s',
	},
});

const copyLinkWrapper = xcss({
	font: token('font.body'),
	opacity: '0',
	position: 'absolute',
	transform: 'translateX(-4px)',
	transition: 'opacity 0.2s ease-out 0s, transform 0.2s ease-out 0s',
});

type ContentTitleProps = {
	banner?: React.ReactNode | null;
	centerTitle: boolean;
	lookAndFeel?: {
		content: {
			header: any;
		};
		headings: any;
	};
	prefix?: string | '';
	title?: string | '';
	children?: React.ReactNode | null;
	hasEmoji: boolean;
	emojiId: string | '';
	contentId: string;
	contentType: string;
	spaceKey: string;
	loading: boolean;
	headingContainer?:
		| React.ComponentType<{
				children: React.ReactNode;
				showInlineCommentButton?: boolean;
		  }>
		| ReactComponentLike;
	hasCoverPicture?: boolean;
	isPageContentFullWidth?: boolean;
	isEmbeddedPage?: boolean;
	titleContentProperties: TitleContentPropertiesType;
};

type NullableQueryParams = {
	param: string | string[] | null;
};

const isURLVisible = () => window.top === window.self;

export const ContentTitleComponent: FC<ContentTitleProps> = ({
	lookAndFeel,
	banner,
	title,
	children,
	centerTitle,
	prefix,
	hasEmoji,
	emojiId,
	contentId,
	spaceKey,
	contentType,
	loading,
	headingContainer: HeadingContainer = React.Fragment,
	hasCoverPicture,
	isPageContentFullWidth,
	isEmbeddedPage,
	titleContentProperties,
}) => {
	const [emojiTitle, setEmojiTitle] = useState<string | null>(emojiId);
	useEffect(() => {
		// for page that does not have emoji, we want to set emoji to null
		// so the emoji doesn't appear twice when user navigate between page via page tree
		if (!hasEmoji) {
			setEmojiTitle(null);
		} else {
			setEmojiTitle(emojiId);
		}
	}, [hasEmoji, emojiId]);

	const { getQueryParams, setQueryParams } = useContext(RoutesContext);

	const { coverPictureWidth } = useTitleContentPropertiesForPublishedPage({
		contentId,
	});
	const isFixedWidthImageOption = coverPictureWidth === COVERPICTUREWIDTH.FIXED;

	const isTitleCenterAligned =
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

	/**
	 * The feature of clearing query params by clicking title is only useful when it's used in standalone Confluence with the URL visible.
	 * When a page gets embedded e.g. rendered within iframe macro, user won't be able to see the effect from clearing query params and the mouse pointer and clicking lead to a confusing user experience.
	 */
	const clearQueryParams = isURLVisible()
		? () => {
				const queryParams = getQueryParams() as NullableQueryParams;
				if (!queryParams) return;
				Object.keys(queryParams).forEach((k) => (queryParams[k] = null));
				setQueryParams(queryParams);
			}
		: undefined;

	const isLeftAlignedTitleWithFixedWidthImageAndEmoji =
		isFixedWidthImageOption &&
		hasEmoji &&
		!isTitleCenterAligned &&
		hasCoverPicture &&
		isPageContentFullWidth;

	useTrackTitleRedactionRendered({ contentId, title });
	const { activeSpotlight } = useRedactionsTour({
		contentId,
		step: RedactionsTourSteps.FIRST,
		firstStepSource: RedactionTourStepSource.titleRedaction,
	});

	const heading = (
		<StyledH1
			id="title-text"
			data-test-id="title-text"
			data-testid="title-text"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={lookAndFeel?.headings}
			onClick={clearQueryParams}
			isTitleCenterAligned={isTitleCenterAligned}
			hasCoverPicture={hasCoverPicture}
			isPageContentFullWidth={isPageContentFullWidth}
			isFixedWidthImageOption={isFixedWidthImageOption}
			isEmbeddedPage={isEmbeddedPage}
			hasEmoji={hasEmoji}
			isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
		>
			{emojiTitle && (
				<EmojiAlignmentWrapper
					isLeftAlignedTitleWithFixedWidthImageAndEmoji={
						isLeftAlignedTitleWithFixedWidthImageAndEmoji
					}
				>
					<EmojiWrapper
						isTitleCenterAligned={isTitleCenterAligned}
						hasCoverPicture={hasCoverPicture}
						isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
					>
						<Text testId="emoji-title">
							<LazyEmojiComponentLoader
								emoji={emojiTitle}
								height={hasCoverPicture ? EMOJI_SIZE_LARGE : EMOJI_SIZE_MEDIUM}
								hasCoverPicture={hasCoverPicture}
								context="viewPage"
								showTooltip
								renderResourcedEmoji
								isPageTitleComponent
							/>
						</Text>
					</EmojiWrapper>
				</EmojiAlignmentWrapper>
			)}
			<TitleTextAlignmentWrapper
				isLeftAlignedTitleWithFixedWidthImageAndEmoji={
					isLeftAlignedTitleWithFixedWidthImageAndEmoji
				}
			>
				<SpotlightManager>
					<SpotlightTarget name={REDACTIONS_TOUR_TARGETS.TITLE_REDACTION}>
						<TitleTextWrapper
							isTitleCenterAligned={isTitleCenterAligned}
							hasCoverPicture={hasCoverPicture}
							hasEmoji={hasEmoji}
							isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
							isEmbeddedPage={isEmbeddedPage}
							id="content-title-id" // this id is required for useIsTitleIntersectingWithHeader
						>
							{prefix ? `${prefix} - ${title}` : title}
							<Box
								as="span"
								paddingInlineStart="space.100"
								xcss={copyLinkWrapper}
								data-vc="copylink-wrapper"
								data-testId="copylink-wrapper"
							>
								<CopyLinkButtonLoader
									shouldResetOnHover
									contentId={contentId}
									spaceKey={spaceKey}
									contentType={contentType}
									pageMode="view"
									source="pageTitle"
									buttonAppearance="subtle-link"
									tooltipPosition="top"
								/>
							</Box>
						</TitleTextWrapper>
					</SpotlightTarget>
					<SpotlightTransition>{activeSpotlight}</SpotlightTransition>
				</SpotlightManager>
			</TitleTextAlignmentWrapper>
		</StyledH1>
	);

	return (
		<div data-testid="title-wrapper">
			<div
				css={[styledContentTitle, centerTitle && centerTitleStyles]}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					...lookAndFeel?.content?.header,
				}}
				data-test-id="page-content-header"
				data-testid="page-content-header"
			>
				<HeadingWrapper hasBanner={!!banner}>
					<HeadingContainer showInlineCommentButton>{heading}</HeadingContainer>
				</HeadingWrapper>
				{banner || null}
				{!loading && <PageSegmentLoadEnd key={contentId} metric={CONTENT_TITLE_METRIC} />}
				{children}
				<ExperienceSuccess name={VIEW_PAGE_TITLE_EXPERIENCE} />
			</div>
		</div>
	);
};
