import type { FC } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';

import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { ReactionsProvider } from '@confluence/comment-context';
import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ReactionsSSRLoader } from '@confluence/reactions/entry-points/ReactionsSSRLoader';
import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

import { ReactionsLoaderBackground as DelayedReactions } from './ReactionsLoader';
import type { ReactionLocation } from './types';
import { ReactionContainerType } from './types';
import { useReactionsQueryParams } from './hooks/useReactionsQueryParams';

const i18n = defineMessages({
	moreEmojis: {
		id: 'reactions.view-page.byline.reactions.more.emojis',
		description: 'Message displayed in button tooltip to show more emojis.',
		defaultMessage: 'More emojis',
	},
});

const ViewPageReactionsContainerCommonStyles = xcss({
	display: 'flex',
	clear: 'both',
	overflow: 'visible',
	maxWidth: 'unset',
	margin: 'unset',
	marginBottom: 'space.025',
	height: '28px',
});

const topMargin = xcss({
	marginTop: 'space.025',
});

type ViewPageReactionsProps = {
	contentId: string;
	contentType?: string | null;
	spaceId?: string | null;
	contentSubType?: string | null;
	noFlexWrap?: boolean;
	noRelativeContainer?: boolean;
};

const ReactionsComponent: FC<ViewPageReactionsProps> = ({
	contentId,
	contentType,
	spaceId,
	contentSubType,
	noFlexWrap,
	noRelativeContainer,
}) => {
	const { formatMessage } = useIntl();
	const [state] = useContentState();
	const { focusedPageReactionsId } = useReactionsQueryParams();
	const { edition } = useSessionData();
	const [reactionsRef, reactionsSectionInView] = useInView({
		rootMargin: '-100px 0px 0px 0px',
	});

	// Used when we want to allow the user to select reactions from the summary view popup
	const customIcon = fg('confluence_new_reactions_byline_changes') ? (
		<ShowMoreHorizontalIcon label={formatMessage(i18n.moreEmojis)} />
	) : undefined;
	const tooltipContent = fg('confluence_new_reactions_byline_changes')
		? formatMessage(i18n.moreEmojis)
		: undefined;

	const displayReactions = () => {
		if (contentType && spaceId) {
			// We want to load in the background ONLY if we have a query param, otherwise we can preload the query
			const ReactionsLoader = Boolean(focusedPageReactionsId)
				? DelayedReactions
				: ReactionsSSRLoader;

			return (
				<Flex xcss={topMargin} testId="view-page-byline-reactions-wrapper">
					<ReactionsLoader
						contentId={contentId}
						location={contentType as ReactionLocation}
						containerId={spaceId}
						containerType={ReactionContainerType.SPACE}
						contentSubType={contentSubType}
						summaryViewEnabled
						summaryViewThreshold={fg('confluence_new_reactions_byline_changes') ? 1 : 2}
						allowSelectFromSummaryView={fg('confluence_new_reactions_byline_changes')}
						useButtonAlignmentStyling
						summaryViewPlacement="bottom-start"
						animateNoReactionsText
						customAnalyticsAttributes={{
							edition,
						}}
						isSubtle
						reactionPickerTriggerIcon={customIcon}
						reactionPickerTriggerToolipContent={tooltipContent}
						useDefaultStyledContainer
						noFlexWrap={noFlexWrap}
						noRelativeContainer={noRelativeContainer}
					/>
				</Flex>
			);
		} else {
			return <ReactionsPlaceholder />;
		}
	};

	return (
		<Box
			ref={reactionsRef}
			testId="view-page-byline-reactions"
			xcss={[ViewPageReactionsContainerCommonStyles]}
		>
			<ReactionsProvider contentId={contentId}>
				{/* eslint-disable-next-line check-react-ssr-usage/no-react-ssr */}
				{!process.env.REACT_SSR && reactionsSectionInView && state?.isContentReady ? (
					displayReactions()
				) : (
					<ReactionsPlaceholder />
				)}
			</ReactionsProvider>
		</Box>
	);
};

export const ViewPageBylineReactions: FC<ViewPageReactionsProps> = (props) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.NOTIFICATIONS}>
			<ReactionsComponent {...props} />
		</TransparentErrorBoundary>
	);
};
