import { useQuery } from '@apollo/react-hooks';

import { CommentButtonQuery } from './CommentButtonQuery.graphql';

export const usePreloadCommentButton = (contentId, isCommentButtonSSRd) => {
	const { data, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CommentButtonQuery,
		{
			fetchPolicy: isCommentButtonSSRd ? 'cache-first' : 'cache-and-network',
			variables: { pageId: contentId },
		},
	);

	return { data, error };
};
