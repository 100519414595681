import React, { type FC, type ReactNode } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Flex, Inline, xcss } from '@atlaskit/primitives';

// This is needed to override styles on the divider since the split button does not really have any configuration
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const WhiteButtonOverrides = styled.div({
	height: '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'> div, > div > div:first-child, > div > div > div:first-child': {
		height: '32px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'> div > div:nth-child(2)': {
		backgroundColor: token('color.border'),
	},
});

const inlineBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	borderRadius: 'border.radius',
});

type WhiteSplitButtonWrapperProps = {
	isWhiteButtonEnabled: boolean;
	children: ReactNode;
};

export const WhiteSplitButtonWrapper: FC<WhiteSplitButtonWrapperProps> = ({
	isWhiteButtonEnabled,
	children,
}) => {
	if (isWhiteButtonEnabled)
		return (
			<Flex>
				<Inline xcss={inlineBorderStyles} grow="hug">
					<WhiteButtonOverrides>{children}</WhiteButtonOverrides>
				</Inline>
			</Flex>
		);
	return <>{children}</>;
};
